.ourteamcard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  margin: 20px;
}

.ourteamcard:hover {
  transform: translateY(-10px);
}

/* Image styling */
.image-wrp {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 15px;
}

.image-wrp img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Text styling */
.infotxt {
  margin: 5px 0;
}

.name p {
  font-weight: 700;
  font-size: 1.2rem;
  color: #333;
}

.branch p {
  font-size: 1rem;
  color: #777;
}

/* Social icons styling */
.ourteam-icon {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 15px;
}

.social {
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s;
}

.social.linkedin:hover {
  color: #0077b5; /* LinkedIn blue */
}

.social.instagram:hover {
  color: #e1306c; /* Instagram gradient */
}

/* Add responsiveness */
@media (max-width: 768px) {
  .ourteamcard {
    width: 100%;
  }

  .image-wrp {
    width: 100px;
    height: 100px;
  }

  .name p {
    font-size: 1.1rem;
  }

  .branch p {
    font-size: 0.9rem;
  }

  .social {
    font-size: 1.2rem;
  }
}
