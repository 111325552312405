.container {
  z-index: 999;
  position: fixed;
  top: 80px;
  right: 20px;
  width: 300px;
  min-height: 100px;
  background-color: #dadada;
  border: 1px solid white;
  border-radius: 10px;
  color: rgb(55, 55, 55);
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-all;
}
