.ourteamwrapper {
  padding: 20px;
  background: #e5e8ef;
  text-align: center;
}

.devcardswrp,
.coordcardswrp {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 20px;
  row-gap: 10px;
  margin: 30px auto 60px;
}

.accordion-item {
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: whitesmoke;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.accordion-header {
  background-color: #f0f0f0;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease, padding 0.3s ease;
}

.accordion-header:hover {
  background-color: #e0e0e0;
}

.accordion-header h3 {
  margin: 0;
  font-size: 20px;
  color: #333;
  font-weight: 600;
}

.accordion-header span {
  font-size: 24px;
  color: #666;
  transition: transform 0.3s ease;
}

.accordion-content {
  padding: 10px;
  background-color: whitesmoke;
  animation: fadeIn 0.3s ease;
}

.accordion-content .devcardswrp {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  justify-content: space-evenly;
}

.ourteamcard {
  width: 220px;
  background-color: white;
  /* padding: 15px; */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.ourteamcard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.ourteamcard img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 10px;
}

.ourteamcard .infotxt p {
  margin: 5px 0;
  font-size: 16px;
  color: #333;
}

.ourteam-icon a {
  margin: 0 8px;
  color: #555;
  transition: color 0.3s ease;
}

.ourteam-icon a:hover {
  color: #0073b1; /* LinkedIn color */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (min-width: 576px) {
  .devcardswrp,
  .coordcardswrp {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
  }
}

@media (min-width: 992px) {
  .devcardswrp,
  .coordcardswrp {
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
  }
}
